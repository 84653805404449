<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Intervenants
            </div>
          </template>
          <v-card-text>
            <v-row>
              <v-col
                md="12"
                cols="12"
              >
              <h2>Avocats intervenants aux ateliers : </h2><br><br>
<table cellspacing="0" border="0" style="margin:auto">
	<colgroup width="202"></colgroup>
	<colgroup width="158"></colgroup>
	<colgroup width="133"></colgroup>
	<colgroup width="265"></colgroup>
	<tbody><tr>
		<td style="border-top: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan="4" height="59" align="center" valign="middle" bgcolor="#1D6295" sdnum="1033;0;D-MMM"><font face="Arial" size="3" color="#FFFFFF">Lundi 5 octobre <br>Passer la crise</font></td>
		</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Fabien ARAKELIAN </font></b></td>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">ABFM</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 18 45 15 54</font></td>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><u><font color="#6B9F25"><a href="mailto:abfm.avocats@orange.fr">abfm.avocats@orange.fr
</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Catherine CASSAN </font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">PWC</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">01 56 57 41 49 </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><u><font color="#6B9F25"><a href="mailto:catherine.cassan@avocats.pwc.com">catherine.cassan@avocats.pwc.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Claude DUVERNOY</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D"><br></font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 72 84 92 43</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><u><font color="#6B9F25"><a href="mailto:claudeduvernoy@droitfil.fr">claudeduvernoy@droitfil.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Michel GUICHARD</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">TAJ</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 73 67 54 40</font></td>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:mguichard@taj.fr">mguichard@taj.fr</a></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Ludivine JOUHANNY</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">JLAvocat</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 86 18 52 44</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:l.jouhanny@cabinetjlavocat.fr">l.jouhanny@cabinetjlavocat.fr</a></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Sonia KOUTCHOUK </font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">Cabinet Koutchouk</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">01 49 10 94 50</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:sk@avocat-koutchouk.com">sk@avocat-koutchouk.com</a></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Vincent MAUREL</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">Bâtonnier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 76 48 81 86</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:batonnier@barreau92.com">batonnier@barreau92.com</a></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Paul MISPELON</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">PWC</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">01 56 57 59 84</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:paul.mispelon@avocats.pwc.com">paul.mispelon@avocats.pwc.com</a></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Laurence REBOULLEAU</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">Cabinet Reboulleau</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">01 47 57 28 34</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:cabinet@reboulleau-avocat.fr">cabinet@reboulleau-avocat.fr</a></font></td>
	</tr>
	<tr>
		<td style="border-left: 2px solid #000000; border-right: 2px solid #000000" colspan="4" height="59" align="center" valign="middle" bgcolor="#1D6295" sdnum="1033;0;D-MMM"><font face="Arial" size="3" color="#FFFFFF">Mardi 6 octobre <br>Entreprendre </font></td>
		</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Alexandre BORDENAVE</font></b></td>
		<td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">BFL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 84 79 37 25</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:alexandre.bordenave@cms-fl.com">alexandre.bordenave@cms-fl.com</a></font></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Sandrine CLEVENOT</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">Corpolegem</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 60 50 24 17</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:sc@corpolegem.com">sc@corpolegem.com</a></font></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Jean-Philippe MARIANI </font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">Cabinet Mariani</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 80 00 11 34 </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:jpmariani@cabinet-m.fr">jpmariani@cabinet-m.fr</a></font></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Vincent MAUREL</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">Bâtonnier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 76 48 81 86</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:batonnier@barreau92.com">batonnier@barreau92.com</a></font></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Arnaud PEDRON </font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">TAJ</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 74 02 93 40</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:apedron@taj.fr">apedron@taj.fr</a></font></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Grégoire PERSON</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">KPMG</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">&nbsp;06 35 55 87 82&nbsp;</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:gperson@kpmgavocats.fr">gperson@kpmgavocats.fr</a></font></td>
	</tr>
	<tr>
		<td style="border-left: 2px solid #000000; border-right: 2px solid #000000" colspan="4" height="59" align="center" valign="middle" bgcolor="#1D6295" sdnum="1033;0;D-MMM"><font face="Arial" size="3" color="#FFFFFF">Mercredi 7 octobre <br>Rester humain </font></td>
		</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Stéphane BALLER </font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">Cabinet Baller </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 15 26 15 01</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:stephane.baller.handicap@gmail.com">stephane.baller.handicap@gmail.com</a></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Eléonore FAVERO</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">Cabinet Favero </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 99 17 49 99</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:eleonore.favero@gmail.com">eleonore.favero@gmail.com </a></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Marie-Lorraine HENRY </font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">Fidal </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 71 45 65 52&nbsp;</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:MarieLorraine.Henry@fidal.com">MarieLorraine.Henry@fidal.com</a></font></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Julie L'HOTEL DELHOUME</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">Cabinet 54</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 72 21 20 24</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:julie.lhotel@cabinet54.com">julie.lhotel@cabinet54.com</a></font></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Fabrice NAFTALSKI </font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">EY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 07 70 87 58</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:fabrice.naftalski@ey-avocats.com">fabrice.naftalski@ey-avocats.com</a></font></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Marie-Pascale PIOT </font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">EY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">01 55 61 18 17</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:marie-pascale.piot@ey-avocats.com">marie-pascale.piot@ey-avocats.com</a></font></td>
	</tr>
	<tr>
		<td style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">Alexia RIGAUT</font></b></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">EY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">06 40 41 22 18&nbsp;</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a href="mailto:alexia.rigaud@ey-avocats.com&nbsp;">alexia.rigaud@ey-avocats.com&nbsp;</a></font></td>
	</tr>
	<tr>
		<td height="16" align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman"><br></font></td>
	</tr>
	<tr>
		<td rowspan="3" height="48" align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br>
		</font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman"><br></font></td>
	</tr>
	<tr>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman"><br></font></td>
	</tr>
	<tr>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman"><br></font></td>
	</tr>
</tbody></table>
                   <br><h2>Avocats des consultations :</h2><br>
<table cellspacing="0" border="0">
	<colgroup width="175"></colgroup>
	<colgroup width="107"></colgroup>
	<colgroup width="301"></colgroup>
	<colgroup width="440"></colgroup>
	<tbody><tr>
		<td colspan="4" height="37" align="center" valign="bottom" bgcolor="#2F5597"><font size="4" color="#FFFFFF">AVOCATS DES CONSULTATIONS </font></td>
		</tr>
	<tr>
		<td height="20" align="left" valign="bottom"><br></td>
		<td align="left" valign="bottom"><br></td>
		<td align="left" valign="bottom"><font color="#4472C4"><br></font></td>
		<td align="left" valign="bottom"><font face="Tahoma"><br></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" height="49" align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Nom</font></b></td>
		<td align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Prénom</font></b></td>
		<td align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Mail </font></b></td>
		<td align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Domaine d'intervention </font></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Abci</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Kamilia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:kamilia.abci@gmail.com">kamilia.abci@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Abci</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Kamilia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:kamilia.abci@gmail.com">kamilia.abci@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">ALBRAND</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sandrine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:albrand.avocat@cabinetasa.com">albrand.avocat@cabinetasa.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">ALBRAND</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sandrine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:albrand.avocat@cabinetasa.com">albrand.avocat@cabinetasa.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des assurances / préjudices corporels</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">ALEXANDRE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Géraldine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:gealexandre@taj.fr">gealexandre@taj.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Fiscalité / Fiscalité des particuliers</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">ALLIBERT PIQUOT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Agnès</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:allibert.piquot.avocat@gmail.com">allibert.piquot.avocat@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">ALLIBERT PIQUOT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Agnès</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:allibert.piquot.avocat@gmail.com">allibert.piquot.avocat@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des mineurs</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BARRIERE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">AnnesSophie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:abarriere@kpmgavocats.fr">abarriere@kpmgavocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BATAILLE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Louis</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Louis.Bataille@ey-avocats.com">Louis.Bataille@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Bauer</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marie-Pierre</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mpbauer@tb-avocats.fr">mpbauer@tb-avocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés / Entreprises en difficulté</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Bauer</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marie-Pierre</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">mpbauer@tb-avocats.fr</font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Bauer</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marie-Pierre</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">mpbauer@tb-avocats.fr</font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BELLA ETOUNDI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Mireille Marlyse</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:bellaetoundi@icloud.com">bellaetoundi@icloud.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BELLA ETOUNDI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Mireille Marlyse</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:bellaetoundi@icloud.com">bellaetoundi@icloud.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des étrangers</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BENNANI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Ali</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:alibennani@kpmgavocats.fr">alibennani@kpmgavocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BISSUEL MENARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Claire</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:cbissuelmenard@taj.fr">cbissuelmenard@taj.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Fiscalité / Fiscalité des particuliers</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BOULAN </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Guillaume</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:gboulan@crtd-avocats.com">gboulan@crtd-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Boulanger</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Olivier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:boulanger.cbo@gmail.com">boulanger.cbo@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Boulanger</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Olivier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:boulanger.cbo@gmail.com">boulanger.cbo@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BOUSQUET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Catherine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:catherinebousquet.avocat@gmail.com">catherinebousquet.avocat@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BOUSQUET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Catherine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:catherine.bousquet@fidal.com">catherine.bousquet@fidal.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés / Entreprises en difficulté</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BRET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Hortense</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">hbret@kpmgavocats.fr</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BRONNET BARDET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Aude</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:aude.bardet@bardet-avocats.com">aude.bardet@bardet-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit immobilier</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BRONNET BARDET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Aude</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:aude.bardet@bardet-avocats.com">aude.bardet@bardet-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">BRONNET BARDET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Aude</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:aude.bardet@bardet-avocats.com">aude.bardet@bardet-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Médiation</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">CADET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">AnnesSophie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:anne.cadet@cms-fl.com">anne.cadet@cms-fl.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">CAILLOL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Emilie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">emilie.caillol@hotmail.fr</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">CAILLOL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Emilie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">emilie.caillol@hotmail.fr</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">CAUBEL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sophie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:avocat@cabinetcaubel.com">avocat@cabinetcaubel.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail / Droit de la sécurité sociale</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">CORDANI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Aurélia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:acordani@toullec-avocat.com">acordani@toullec-avocat.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Voies d'exécution</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">COURREGES</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Christophe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Jean.Christophe.Courreges@ey-avocats.com">Jean.Christophe.Courreges@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">D ANCONA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pascale</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">pascale.d.ancona@ey-avocats.com</font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">D ANCONA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pascale</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">pascale.d.ancona@ey-avocats.com</font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des nouvelles technologies/Propriété intellectuelle (dont RGPD)</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DAL VECCHIO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Frédéric</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:fredericdalvecchio@fdv-avocat.fr">fredericdalvecchio@fdv-avocat.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DAMYANOVA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Irina</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:idamyanova@taj.fr">idamyanova@taj.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Fiscalité / Fiscalité des particuliers</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">de VARAX</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Baptiste</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jean-baptiste.de-varax@fidal.com">jean-baptiste.de-varax@fidal.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit bancaire/boursier</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">de VARAX</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Baptiste</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jean-baptiste.de-varax@fidal.com">jean-baptiste.de-varax@fidal.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des assurances</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DAOUADI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Nabil</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">Nabil.Daoudi@ey-avocats.com</font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Fiscalité / Fiscalité des particuliers</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DELELIS</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Morgane</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mdelelis@kpmgavocats.fr">mdelelis@kpmgavocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DELSART</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Christophe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:delsart@delsart.eu">delsart@delsart.eu</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DELSART</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Christophe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:delsart@delsart.eu">delsart@delsart.eu</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Médiation</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DEMIR</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Raja</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">rdemir@kpmgavocats.fr</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Diedisheim</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Celia </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:cd.avocat@cabinetcaubel.com">cd.avocat@cabinetcaubel.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DIRAT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Caroline</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:caroline.dirat@ey-avocats.com">caroline.dirat@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DJIAN</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Franck</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">fd@fdavocat.com</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DUNIKOWSKI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Stéphane</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:avocatdunikowski@d-avocat.fr">avocatdunikowski@d-avocat.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DUNIKOWSKI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Stéphane</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:avocatdunikowski@d-avocat.fr">avocatdunikowski@d-avocat.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des étrangers</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DURAND</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sam</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:samdurand@kpmgavocats.fr">samdurand@kpmgavocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit du sport</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DURAND</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sam</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:samdurand@kpmgavocats.fr">samdurand@kpmgavocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">DUVERNOY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Claude</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:claudeduvernoy@droitfil.fr">claudeduvernoy@droitfil.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Médiation </font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">FARTARIA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pauline</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">pfartaria@kpmgavocats.fr</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">FERNET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">mfernet@kpmgavocats.fr</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">FIAWOO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Clyde</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Clyde.Fiawoo@ey-avocats.com">Clyde.Fiawoo@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">FOURNIER-DEDOYARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sophie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:fournier-dedoyard@kpmgavocats.fr">fournier-dedoyard@kpmgavocats.fr </a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal / Fiscailité des entreprises</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">GANEM</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Emilie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:emilie.ganem@cabinet54.com">emilie.ganem@cabinet54.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des étrangers / Droit des étrangers</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">GERARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Philippe </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:gerardavocat92@gmail.com">gerardavocat92@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">GERARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Philippe </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:gerardavocat92@gmail.com">gerardavocat92@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">GERARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Philippe </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:gerardavocat92@gmail.com">gerardavocat92@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">HERRY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Caroline</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:herry.caroline@yahoo.fr">herry.caroline@yahoo.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des assurances / préjudices corporels</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">HERRY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Caroline</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:herry.caroline@yahoo.fr">herry.caroline@yahoo.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">IRLINGER</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marion</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mirlinger@taj.fr">mirlinger@taj.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal / fiscailité des particuliers</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">J'MILA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Youssef</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">yjmila@kpmgavocats.fr</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">JEUGE DOUNGUE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Martial</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jeugue.avocat@gmail.com">jeugue.avocat@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">JEUGE DOUNGUE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Martial</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jeugue.avocat@gmail.com">jeugue.avocat@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">JEUGE DOUNGUE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Martial</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jeugue.avocat@gmail.com">jeugue.avocat@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">JOHANN</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sophie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:sjohann@taj.fr">sjohann@taj.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal / Fiscalité des particuliers</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">JOLICARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Patricia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:patricia.jolicard@fidal.com">patricia.jolicard@fidal.com </a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">JOUHANNY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Ludivine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:l.jouhanny@cabinetjlavocat.fr">l.jouhanny@cabinetjlavocat.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés / Entreprises en difficulté</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">KHOUILI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Madiha</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mk.avocat@yahoo.fr">mk.avocat@yahoo.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des assurances / préjudices corporels</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">KHOUILI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Madiha</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mk.avocat@yahoo.fr">mk.avocat@yahoo.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">KHOUILI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Madiha</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mk.avocat@yahoo.fr">mk.avocat@yahoo.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Koutchouk</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sonia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:sk@avocat-koutchouk.com">sk@avocat-koutchouk.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Koutchouk</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sonia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:sk@avocat-koutchouk.com">sk@avocat-koutchouk.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Médiation</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">LAMORA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Stéphanie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:s.lamora@bdsl-avocats.fr">s.lamora@bdsl-avocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des nouvelles technologies/Propriété intellectuelle (dont RGPD)</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">LAMORA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Stéphanie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:s.lamora@bdsl-avocats.fr">s.lamora@bdsl-avocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit immobilier</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">LAMORA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Stéphanie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:s.lamora@bdsl-avocats.fr">s.lamora@bdsl-avocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">LAOUTAK</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Yacine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:ylaoutak@taj.fr">ylaoutak@taj.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Fiscalité / Fiscalité des particuliers</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">LASSALLE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Rémi</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">rlassalle@kpmgavocats.fr</font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">LAUGERY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pierre-Ann</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Laugery109@gmail.com">Laugery109@gmail.com&nbsp;</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">LE BOURDON</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Amaury</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:amaury.lebourdon@cms-fl.com">amaury.lebourdon@cms-fl.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">LE BOURDON</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Amaury</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:amaury.lebourdon@cms-fl.com">amaury.lebourdon@cms-fl.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit du crédit et de la consommation</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">Leroy </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Michael</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:michael.leroy-avocat@outlook.fr">michael.leroy-avocat@outlook.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de l'environnement</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">LHERMET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Benoit</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Benoit.Lhermet@ey-avocats.com">Benoit.Lhermet@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité / fisaclité des enteprises</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">MARIANI </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Philippe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jpmariani@cabinet-m.fr">jpmariani@cabinet-m.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">MARIANI </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Philippe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jpmariani@cabinet-m.fr">jpmariani@cabinet-m.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit immobilier</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">MARIANI </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Philippe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jpmariani@cabinet-m.fr">jpmariani@cabinet-m.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Médiation</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">MARIANI </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Philippe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jpmariani@cabinet-m.fr">jpmariani@cabinet-m.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">MELCHER-VINCKEVLEUGEL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Karine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:karine.vinckevleugel@fidal.com">karine.vinckevleugel@fidal.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">MELCHER-VINCKEVLEUGEL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Karine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:karine.vinckevleugel@fidal.com">karine.vinckevleugel@fidal.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des associations</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">MINCU</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Daniela</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:daniela.mincu@danielacinileanu.ro">daniela.mincu@danielacinileanu.ro</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés / Entreprises en difficulté</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">MINCU</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Daniela</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:daniela.mincu@danielacinileanu.ro">daniela.mincu@danielacinileanu.ro</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">NIAMBA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Laetitia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:laetitia.niamba@anetia.fr">laetitia.niamba@anetia.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">NIAMBA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Laetitia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:laetitia.niamba@anetia.fr">laetitia.niamba@anetia.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">PARLANT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Cyril</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:cyril.parlant@fidal.com">cyril.parlant@fidal.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">PEDRON</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Arnaud </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">apedron@taj.fr</font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">droit des affaires / droits des sociétés / entreprises en difficulté&nbsp;</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">PESTRE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Mayeul</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mpestre@kpmgavocats.fr">mpestre@kpmgavocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">PESTRE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Mayeul</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mpestre@kpmgavocats.fr">mpestre@kpmgavocats.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit immobilier</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">PIOT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marie-Pascale</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:marie-pascale.piot@ey-avocats.com">marie-pascale.piot@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">PITTE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Carole-Andrée</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:cpitte@taj.fr">cpitte@taj.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">POH MANZAM</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Liliane</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:liliane.poh@anetia.fr">liliane.poh@anetia.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">RAINAUT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pauline</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:paulinerainaut.avocat@gmail.com">paulinerainaut.avocat@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">RAKROUKI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Souleymen</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Souleymen.Rakrouki@ey-avocats.com">Souleymen.Rakrouki@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000"><br></font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font size="3" color="#000000">RONGEAT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Prescillia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Prescillia.Rongeat@ey-avocats.com">Prescillia.Rongeat@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font size="3" color="#000000">RONGEAT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Prescillia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Prescillia.Rongeat@ey-avocats.com">Prescillia.Rongeat@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font size="3" color="#000000">ROUANNET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Mathilde</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:rouannet.avocat@gmail.com">rouannet.avocat@gmail.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font size="3" color="#000000">SANTINI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Frédéric</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:fsantini@crtd-avocats.com">fsantini@crtd-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit immobilier / Construction</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font size="3" color="#000000">SANTINI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Frédéric</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:fsantini@crtd-avocats.com">fsantini@crtd-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des assurances / Construction</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font size="3" color="#000000">SCHEFFLER</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Catherine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:catherine.scheffler@yahoo.fr">catherine.scheffler@yahoo.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font size="3" color="#000000">SCHEFFLER</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Catherine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:catherine.scheffler@yahoo.fr">catherine.scheffler@yahoo.fr</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des mineurs</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font size="3" color="#000000">SERY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sandra</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Sandra.Sery@ey-avocats.com">Sandra.Sery@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal / Fiscailité des entreprises</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font size="3" color="#000000">VILLE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Léonore</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:leonore.ville@ey-avocats.com">leonore.ville@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font size="3" color="#000000">ZIOUCHE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Abdallah</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Abdallah.Ziouche@ey-avocats.com">Abdallah.Ziouche@ey-avocats.com</a></font></u></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité</font></td>
	</tr>
</tbody></table>
                   <br><h2>Avocats par domaine d'intervention :</h2><br>
<table cellspacing="0" border="0">
	<colgroup width="432"></colgroup>
	<colgroup width="175"></colgroup>
	<colgroup width="107"></colgroup>
	<colgroup width="301"></colgroup>
	<tbody><tr>
		<td colspan="4" height="37" align="center" valign="middle" bgcolor="#2F5597"><font size="4" color="#FFFFFF">AVOCATS DES CONSULTATIONS </font></td>
		</tr>
	<tr>
		<td height="20" align="left" valign="bottom"><font color="#000000"><br></font></td>
		<td align="left" valign="bottom"><br></td>
		<td align="left" valign="bottom"><br></td>
		<td align="left" valign="bottom"><font color="#4472C4"><br></font></td>
	</tr>
	<tr>
		<td height="49" align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Domaine d'intervention </font></b></td>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Nom</font></b></td>
		<td align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Prénom</font></b></td>
		<td align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Mail </font></b></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit bancaire/boursier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">de VARAX</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Baptiste</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jean-baptiste.de-varax@fidal.com">jean-baptiste.de-varax@fidal.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de l'environnement</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Leroy </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Michael</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:michael.leroy-avocat@outlook.fr">michael.leroy-avocat@outlook.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Abci</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Kamilia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:kamilia.abci@gmail.com">kamilia.abci@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">ALBRAND</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sandrine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:albrand.avocat@cabinetasa.com">albrand.avocat@cabinetasa.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">ALLIBERT PIQUOT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Agnès</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:allibert.piquot.avocat@gmail.com">allibert.piquot.avocat@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Bauer</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marie-Pierre</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">mpbauer@tb-avocats.fr</font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BELLA ETOUNDI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Mireille Marlyse</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:bellaetoundi@icloud.com">bellaetoundi@icloud.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BRONNET BARDET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Aude</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:aude.bardet@bardet-avocats.com">aude.bardet@bardet-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">CAILLOL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Emilie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">emilie.caillol@hotmail.fr</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DUNIKOWSKI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Stéphane</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:avocatdunikowski@d-avocat.fr">avocatdunikowski@d-avocat.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">GERARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Philippe </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:gerardavocat92@gmail.com">gerardavocat92@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">JEUGE DOUNGUE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Martial</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jeugue.avocat@gmail.com">jeugue.avocat@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">KHOUILI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Madiha</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mk.avocat@yahoo.fr">mk.avocat@yahoo.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Koutchouk</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sonia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mpbauer@tb-avocats.fr">sk@avocat-koutchouk.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">LAMORA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Stéphanie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:s.lamora@bdsl-avocats.fr">s.lamora@bdsl-avocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">POH MANZAM</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Liliane</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:liliane.poh@anetia.fr">liliane.poh@anetia.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit de la famille</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">ROUANNET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Mathilde</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:rouannet.avocat@gmail.com">rouannet.avocat@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BATAILLE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Louis</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Louis.Bataille@ey-avocats.com">Louis.Bataille@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BENNANI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Ali</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:alibennani@kpmgavocats.fr">alibennani@kpmgavocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Boulanger</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Olivier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:boulanger.cbo@gmail.com">boulanger.cbo@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BOUSQUET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Catherine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:catherinebousquet.avocat@gmail.com">catherinebousquet.avocat@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BRET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Hortense</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">hbret@kpmgavocats.fr</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">D ANCONA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pascale</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">pascale.d.ancona@ey-avocats.com</font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DEMIR</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Raja</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">rdemir@kpmgavocats.fr</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">FARTARIA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pauline</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">pfartaria@kpmgavocats.fr</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">LE BOURDON</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Amaury</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:amaury.lebourdon@cms-fl.com">amaury.lebourdon@cms-fl.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">NIAMBA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Laetitia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:laetitia.niamba@anetia.fr">laetitia.niamba@anetia.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">PESTRE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Mayeul</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mpestre@kpmgavocats.fr">mpestre@kpmgavocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">RONGEAT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Prescillia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Prescillia.Rongeat@ey-avocats.com">Prescillia.Rongeat@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés / Entreprises en difficulté</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Bauer</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marie-Pierre</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mpbauer@tb-avocats.fr">mpbauer@tb-avocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés / Entreprises en difficulté</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BOUSQUET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Catherine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:catherine.bousquet@fidal.com">catherine.bousquet@fidal.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés / Entreprises en difficulté</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">JOUHANNY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Ludivine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:l.jouhanny@cabinetjlavocat.fr">l.jouhanny@cabinetjlavocat.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des affaires/droit des sociétés / Entreprises en difficulté</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">MINCU</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Daniela</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:daniela.mincu@danielacinileanu.ro">daniela.mincu@danielacinileanu.ro</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">droit des affaires / droits des sociétés / entreprises en difficulté</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">PEDRON</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Arnaud </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">apedron@taj.fr</font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des associations</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">MELCHER-VINCKEVLEUGEL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Karine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:karine.vinckevleugel@fidal.com">karine.vinckevleugel@fidal.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des assurances</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">de VARAX</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Baptiste</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jean-baptiste.de-varax@fidal.com">jean-baptiste.de-varax@fidal.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des assurances / Construction</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">SANTINI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Frédéric</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:fsantini@crtd-avocats.com">fsantini@crtd-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des assurances / préjudices corporels</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">ALBRAND</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sandrine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:albrand.avocat@cabinetasa.com">albrand.avocat@cabinetasa.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des assurances / préjudices corporels</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">HERRY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Caroline</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:herry.caroline@yahoo.fr">herry.caroline@yahoo.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des assurances / préjudices corporels</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">KHOUILI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Madiha</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mk.avocat@yahoo.fr">mk.avocat@yahoo.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des étrangers</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BELLA ETOUNDI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Mireille Marlyse</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:bellaetoundi@icloud.com">bellaetoundi@icloud.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des étrangers</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DUNIKOWSKI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Stéphane</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:avocatdunikowski@d-avocat.fr">avocatdunikowski@d-avocat.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des étrangers / Droit des étrangers</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">GANEM</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Emilie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:emilie.ganem@cabinet54.com">emilie.ganem@cabinet54.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des mineurs</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">ALLIBERT PIQUOT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Agnès</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:allibert.piquot.avocat@gmail.com">allibert.piquot.avocat@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des mineurs</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">SCHEFFLER</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Catherine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:catherine.scheffler@yahoo.fr">catherine.scheffler@yahoo.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des nouvelles technologies/Propriété intellectuelle (dont RGPD)</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">D ANCONA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pascale</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">pascale.d.ancona@ey-avocats.com</font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit des nouvelles technologies/Propriété intellectuelle (dont RGPD)</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">LAMORA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Stéphanie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:s.lamora@bdsl-avocats.fr">s.lamora@bdsl-avocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit du crédit et de la consommation</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">LE BOURDON</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Amaury</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:amaury.lebourdon@cms-fl.com">amaury.lebourdon@cms-fl.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit du sport</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DURAND</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sam</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:samdurand@kpmgavocats.fr">samdurand@kpmgavocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Bauer</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marie-Pierre</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">mpbauer@tb-avocats.fr</font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Boulanger</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Olivier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:boulanger.cbo@gmail.com">boulanger.cbo@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">GERARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Philippe </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:gerardavocat92@gmail.com">gerardavocat92@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BARRIERE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">AnnesSophie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:abarriere@kpmgavocats.fr">abarriere@kpmgavocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">CADET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">AnnesSophie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:anne.cadet@cms-fl.com">anne.cadet@cms-fl.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DAL VECCHIO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Frédéric</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:fredericdalvecchio@fdv-avocat.fr">fredericdalvecchio@fdv-avocat.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DJIAN</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Franck</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">fd@fdavocat.com</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">FERNET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">mfernet@kpmgavocats.fr</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">J'MILA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Youssef</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">yjmila@kpmgavocats.fr</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">MINCU</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Daniela</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:daniela.mincu@danielacinileanu.ro">daniela.mincu@danielacinileanu.ro</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pitté</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Carole-Andrée</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:cpitte@taj.fr">cpitte@taj.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal / Fiscailité des entreprises</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">FOURNIER-DEDOYARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sophie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:fournier-dedoyard@kpmgavocats.fr">fournier-dedoyard@kpmgavocats.fr </a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal / Fiscailité des entreprises</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">SERY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sandra</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Sandra.Sery@ey-avocats.com">Sandra.Sery@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal / fiscailité des particuliers</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">IRLINGER</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marion</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mirlinger@taj.fr">mirlinger@taj.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal / Fiscalité des particuliers</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">JOHANN</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sophie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:sjohann@taj.fr">sjohann@taj.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit fiscal / Fiscalité des particuliers</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DAOUADI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Nabil</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Nabil.daoudi@ey-avocats.com">&nbsp;&nbsp;Nabil.daoudi@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit immobilier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BRONNET BARDET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Aude</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:aude.bardet@bardet-avocats.com">aude.bardet@bardet-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit immobilier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">LAMORA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Stéphanie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:s.lamora@bdsl-avocats.fr">s.lamora@bdsl-avocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit immobilier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">MARIANI </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Philippe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jpmariani@cabinet-m.fr">jpmariani@cabinet-m.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit immobilier</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">PESTRE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Mayeul</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mpestre@kpmgavocats.fr">mpestre@kpmgavocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit immobilier / Construction</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">SANTINI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Frédéric</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:fsantini@crtd-avocats.com">fsantini@crtd-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Abci</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Kamilia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:kamilia.abci@gmail.com">kamilia.abci@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">CAILLOL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Emilie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><font size="3" color="#000000">emilie.caillol@hotmail.fr</font></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">GERARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Philippe </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:gerardavocat92@gmail.com">gerardavocat92@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">HERRY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Caroline</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:herry.caroline@yahoo.fr">herry.caroline@yahoo.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">JEUGE DOUNGUE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Martial</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jeugue.avocat@gmail.com">jeugue.avocat@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">KHOUILI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Madiha</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mk.avocat@yahoo.fr">mk.avocat@yahoo.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">LAUGERY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pierre-Ann</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Laugery109@gmail.com">Laugery109@gmail.com&nbsp;</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">MELCHER-VINCKEVLEUGEL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Karine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:karine.vinckevleugel@fidal.com">karine.vinckevleugel@fidal.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">RAINAUT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Pauline</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:paulinerainaut.avocat@gmail.com">paulinerainaut.avocat@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit pénal</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">SCHEFFLER</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Catherine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:catherine.scheffler@yahoo.fr">catherine.scheffler@yahoo.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BOULAN </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Guillaume</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:gboulan@crtd-avocats.com">gboulan@crtd-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">COURREGES</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Christophe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Jean.Christophe.Courreges@ey-avocats.com">Jean.Christophe.Courreges@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DELELIS</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Morgane</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:mdelelis@kpmgavocats.fr">mdelelis@kpmgavocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DELSART</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Christophe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:delsart@delsart.eu">delsart@delsart.eu</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Diedisheim</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Celia </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:cd.avocat@cabinetcaubel.com">cd.avocat@cabinetcaubel.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DIRAT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Caroline</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:caroline.dirat@ey-avocats.com">caroline.dirat@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DURAND</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sam</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:samdurand@kpmgavocats.fr">samdurand@kpmgavocats.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">JEUGE DOUNGUE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Martial</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jeugue.avocat@gmail.com">jeugue.avocat@gmail.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">LASSALLE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Rémi</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000">rlassalle@kpmgavocats.fr</font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">MARIANI </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Philippe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jpmariani@cabinet-m.fr">jpmariani@cabinet-m.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">MARIANI </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Philippe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jpmariani@cabinet-m.fr">jpmariani@cabinet-m.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">NIAMBA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Laetitia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:laetitia.niamba@anetia.fr">laetitia.niamba@anetia.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">PARLANT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Cyril</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:cyril.parlant@fidal.com">cyril.parlant@fidal.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">PIOT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Marie-Pascale</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:marie-pascale.piot@ey-avocats.com">marie-pascale.piot@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Droit social/droit du travail / Droit de la sécurité sociale</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">CAUBEL</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sophie</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:avocat@cabinetcaubel.com">avocat@cabinetcaubel.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">FIAWOO</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Clyde</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Clyde.Fiawoo@ey-avocats.com">Clyde.Fiawoo@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">JOLICARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Patricia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:patricia.jolicard@fidal.com">patricia.jolicard@fidal.com </a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">RONGEAT</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Prescillia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Prescillia.Rongeat@ey-avocats.com">Prescillia.Rongeat@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">VILLE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Léonore</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:leonore.ville@ey-avocats.com">leonore.ville@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">ZIOUCHE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Abdallah</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Abdallah.Ziouche@ey-avocats.com">Abdallah.Ziouche@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">fiscalité / fisaclité des enteprises</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">LHERMET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Benoit</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Benoit.Lhermet@ey-avocats.com">Benoit.Lhermet@ey-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Fiscalité / Fiscalité des particuliers</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">ALEXANDRE</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Géraldine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:gealexandre@taj.fr">gealexandre@taj.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Fiscalité / Fiscalité des particuliers</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BISSUEL MENARD</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Claire</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:cbissuelmenard@taj.fr">cbissuelmenard@taj.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Fiscalité / Fiscalité des particuliers</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DAMYANOVA</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Irina</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:idamyanova@taj.fr">idamyanova@taj.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Fiscalité / Fiscalité des particuliers</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">LAOUTAK</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Yacine</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:ylaoutak@taj.fr">ylaoutak@taj.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Médiation</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">BRONNET BARDET</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Aude</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:aude.bardet@bardet-avocats.com">aude.bardet@bardet-avocats.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Médiation</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DELSART</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Christophe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:delsart@delsart.eu">delsart@delsart.eu</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Médiation</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Koutchouk</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Sonia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:sk@avocat-koutchouk.com">sk@avocat-koutchouk.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Médiation</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">MARIANI </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Jean-Philippe</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:jpmariani@cabinet-m.fr">jpmariani@cabinet-m.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Médiation </font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">DUVERNOY</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Claude</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:claudeduvernoy@droitfil.fr">claudeduvernoy@droitfil.fr</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">Voies d'exécution</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">CORDANI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Aurélia</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:acordani@toullec-avocat.com">acordani@toullec-avocat.com</a></font></u></td>
	</tr>
	<tr>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000"><br></font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">RAKROUKI</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">Souleymen</font></td>
		<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a href="mailto:Souleymen.Rakrouki@ey-avocats.com">Souleymen.Rakrouki@ey-avocats.com</a></font></u></td>
	</tr>
</tbody></table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Intervenants',
  }
</script>
